.modalContainer {
  position: fixed;
  z-index: 50;
  display: grid;
  height: 100%;
  width: 100%;
  background: linear-gradient(to bottom, #091c41 0%, #152746 50%, #0b1a35 100%);
  color: #fff;
}

.onboardingContent {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  border-radius: 20px;
  padding: 3rem 1rem;
}

.onboardingLogin {
  position: relative;
  margin: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 1rem;
  background-color: #1a202c;
  padding: 2.25rem;
  font-size: 1.25rem;
  color: white;
  box-shadow: 0px 12px 16px rgba(0, 0, 0, 0.5);
  border: 1px solid #ffffff18;
}

.bottom {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 30%;
}

.grid {
  background: #0e1c3f;
  background-image: url("/images/grid.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
}

.stars {
  background-image: url("/images/stars.png");
  width: 100%;
  height: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  background-color: #091c41;
  position: absolute;
  top: 0;
  left: 0;
}

.stars .modalContainer {
  background: transparent;
}
